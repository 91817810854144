


























import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OUserGroupList: () => import('@/components/organisms/panel/user/o-user-group-list.vue')},

  setup(){
    const state = reactive({
      search: ''
    })

    return { state }
  }
})
